// import { getTextFromATag } from "../utils/utils";
import ServiceEndpoints from "./serviceEndpoints";

export async function getBeaches(lang, page, title_filter, config) {
    let testCards = [];
    const regex = /<img.*?src=['"](.*?)['"]/;
    await ServiceEndpoints.listOfContent("beaches", lang, page, title_filter, config)
      .then((result) => {
        result.data.rows.forEach((element) => {
          let testElement = {
            title: element.title ? element.title : "",
            activities: element.activities ? element.activities : "",
            closing_date: element.closing_date ? formatDate(element.closing_date) : "",
            content: element.content ? element.content : "",
            images: element.images.length > 0 ? regex.exec(element.images)[1] : "",
            length: element.length ? element.length : "",
            opening_date: element.opening_date ? formatDate(element.opening_date) : "",
            sand: element.sand ? element.sand : "",
            services: element.services ? element.services : "",
            slope: element.slope ? element.slope : "",
            id: element.nid ? element.nid : "", 
            width: element.width ? element.width : "",
            location_latitude: element.location_latitude ? element.location_latitude : "",
            location_longitude: element.location_longitude ? element.location_longitude : "",
            pager: result.data.pager,
          };
          testCards.push(testElement);
        });
  
        return testCards;
      })
      .catch((error) => {
        throw error;
      });
    return testCards;
  }

  export async function getBeach(lang, nid, config) {
    let testCards = [];
    const regex = /<img.*?src=['"](.*?)['"]/;
    await ServiceEndpoints.currentContent("beaches", lang, nid, config)
      .then((result) => {
        result.data.rows.forEach((element) => {
          let testElement = {
            title: element.title ? element.title : "",
            activities: element.activities ? element.activities : "",
            closing_date: element.closing_date ? formatDate(element.closing_date) : "",
            content: element.content ? element.content : "",
            images: element.images.length > 0 ? regex.exec(element.images)[1] : "",
            length: element.length ? element.length : "",
            opening_date: element.opening_date ? formatDate(element.opening_date) : "",
            sand: element.sand ? element.sand : "",
            services: element.services ? element.services : "",
            slope: element.slope ? element.slope : "",
            id: element.nid ? element.nid : "", 
            width: element.width ? element.width : "",
            location_latitude: element.location_latitude ? element.location_latitude : "",
            location_longitude: element.location_longitude ? element.location_longitude : "",
            pager: result.data.pager,
          };
          testCards.push(testElement);
        });
  
        return testCards;
      })
      .catch((error) => {
        throw error;
      });
    return testCards;
  }
  
  function formatDate(isoDateString) {
    const date = new Date(Date.parse(isoDateString));
  
    if (isNaN(date)) {
      // Handle invalid date strings
      return "Invalid Date";
    }
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  
  
  


// function getCorrectRightColumn(text) {
//     let correctText = ""
//     switch (localStorage.getItem("lang")) {
//         case "en": correctText = getSeason(text, "season"); break;
//         case "es": correctText = getSeason(text, "Temporada"); break;
//         case "ca": correctText = getSeason(text, "Temporada"); break;
//         case "fr": correctText = getSeason(text, "Période"); break;
//         case "de": correctText = getSeason(text, "saison"); break;
//         case "ru": correctText = getSeason(text, "Сезон"); break;
//         default: correctText = getSeason(text, "season"); break;
//     }
//     return correctText
// }


// Este metodo lo que hace es hacer un slip por /n para dividir por los saltos de line y que sea mas facil trabjar con los datos
// Se busca la frase que tenga en "filter" y luego con unas expresiones regulares, se saca el dato que se quiere
// function getSeason(text, filter) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes(filter)) {
//             correctText = element.split(":")[0];
//             correctText = correctText.replace(/<\/?[^>]+(>|$)/g, '')
//             correctText = correctText.trimRight()
//         }
//     });
//     return correctText
// }

// function getSeasonEN(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("season")) {
//             correctText = element.match(/(.+?)season/)[0];
//             correctText = correctText.replace(/<\/?[^>]+(>|$)/g, '')
//         }
//     });
//     return correctText
// }
// function getSeasonES(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("Temporada")) {
//             correctText = element.split(":")[0];
//         }
//     });
//     return correctText
// }
// function getSeasonCA(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("Temporada")) {
//             correctText = element.split(":")[0];
//         }
//     });
//     return correctText
// }
// function getSeasonFR(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("Période")) {
//             correctText = element.split(":")[0];
//         }
//     });
//     return correctText
// }
// function getSeasonDE(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("saison")) {
//             correctText = element.split(":")[0];
//             correctText = correctText.replace(/<\/?[^>]+(>|$)/g, '')
//         }
//     });
//     return correctText
// }
// function getSeasonRU(text) {
//     let correctText = text
//     text.split("\n").forEach(element => {
//         if (element.includes("Сезон")) {
//             correctText = element.split(":")[0];
//             correctText = correctText.replace(/<\/?[^>]+(>|$)/g, '')
//         }
//     });
//     return correctText
// }