import axios from "axios";
const https = require('https');
import { getBeaches, getBeach } from "./beaches";
import { getActivities, getActivity } from "./activities";
import { getAgenda, getActivityAgenda } from "./agenda";
import { getCulture, getActivityCulture } from "./culture";

// const API_URL_PATH = "https://cms.lloret.air-institute.com";
const API_URL_PATH = "https://cms.lloret.cat";
// const API_URL_INTEGRATION = "https://turisme.lloret.cat:8443/api";
// const API_URL_INTEGRATION = "https://turisme.lloret.cat";
//const API_URL_INTEGRATION = "https://smct-c12-turisme.lloret.cat/api";
// const API_URL_INTEGRATION = "http://192.168.1.132:3050/api";
// const API_KEY = "oW2j^@4iO$%5'_kPm]!N+Z5v)Dw(2}"
//const API_KEY = "cGkNg-+0uC;H.tI~83n(_eQS;@DP~PqM1$+}@z$EKjv)[5tygW";



const serverURL = "https://smct-c12-turisme.lloret.cat/api/";

const agent = new https.Agent({
  rejectUnauthorized: false
});

export async function iniciatePWA() {
  const url = `${serverURL}stats/app_access`;

  const session = {
    value: 1,
    date: Math.floor(new Date().getTime() / 1000), 
    api_key: "cGkNg-+0uC;H.tI~83n(_eQS;@DP~PqM1$+}@z$EKjv)[5tygW"
  };

  try {
    const params = JSON.stringify(session);

    await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      httpsAgent: agent
    });
  } catch (error) {
    console.error('Error en la solicitud:', error.message);
    console.error('Detalles del error:', error.response ? error.response.data : 'No hay detalles adicionales disponibles');
  }
}



const username = 'lloret';
const password = 'c20_lloret_2024!';

const config = {
    username: username,
    password: password
};



export async function terminatePWA() {
  const fechaActual = new Date();
  let totalTime = (fechaActual.getTime() - localStorage.getItem("date")) / 1000
  const url = `${serverURL}stats/time_stay`;

  const session = {
    value: totalTime,
    date: Math.floor(new Date().getTime() / 1000), 
    api_key: "cGkNg-+0uC;H.tI~83n(_eQS;@DP~PqM1$+}@z$EKjv)[5tygW"
  };

  try {
    const params = JSON.stringify(session);

    await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      httpsAgent: agent
    });
  } catch (error) {
    console.error('Error en la solicitud:', error.message);
    console.error('Detalles del error:', error.response ? error.response.data : 'No hay detalles adicionales disponibles');
  }
}


class ServiceEndpoints {
  content(nid, langCode){
     if(langCode === 'es')
      return axios.get(API_URL_PATH + '/node/' + nid + '?_format=' + 'json',config)
     else
      return axios.get(API_URL_PATH + '/' + langCode + '/node/' + nid + '?_format=' + 'json',config)
  }

  listOfContent(type, langCode, page, title_filter, category_filter, time_start_min, time_start_max, items_per_page) {
    let url = `${API_URL_PATH}/${langCode}/rest/${type}?_format=json&page=${page}&title=${title_filter}`;

    if(time_start_min && time_start_max){
      url += `&period_start[max]=${time_start_max}&period_end[min]=${time_start_min}&time_start[max]=${time_start_max}&time_end[min]=${time_start_min}`;
    }

    if (category_filter) {
        url += `&category=${category_filter}`;
    }

    if(items_per_page){
      url += `&items_per_page=${items_per_page}`;
    }
      
    return axios.get(url, config);
  }

  currentContent(type, langCode, nid) {
    let url = `${API_URL_PATH}/${langCode}/rest/${type}?_format=json&nid=${nid}`;
    return axios.get(url, config);
  }

  getContent(page){
      return axios.get(API_URL_PATH + 'archive?page=' + page + '&_format=' + 'json',config)
  }
}
export default new ServiceEndpoints();

export async function getInfoData(route,lang, page = 0, title_filter = "", category_filter = "", time_start_min, time_start_max, items_per_page) {
  let dataToSend = []
  if(lang){
    switch (route) {
      case "platja": await getBeaches(lang, page, title_filter).then((result) => { dataToSend = result}); break;
      case "activity": await getActivities(lang, page, title_filter, category_filter).then((result) => { dataToSend = result }); break;
      case "agenda": await getAgenda(lang, page, title_filter, category_filter, time_start_min, time_start_max, items_per_page).then((result) => { dataToSend = result }); break;
      case "culture": await getCulture(lang, page, title_filter).then((result) => { dataToSend = result }); break;
      default:
        return dataToSend
    }
  }
  
  return dataToSend
}

export async function getCurrentInfoData(route,lang, nid = "") {
  let dataToSend = []
  if(lang){
    switch (route) {
      case "platja": await getBeach(lang, nid).then((result) => { dataToSend = result}); break;
      case "activity": await getActivity(lang, nid).then((result) => { dataToSend = result }); break;
      case "agenda": await getActivityAgenda(lang, nid).then((result) => { dataToSend = result }); break;
      case "culture": await getActivityCulture(lang, nid).then((result) => { dataToSend = result }); break;
      default:
        return dataToSend
    }
  }
  
  return dataToSend
}