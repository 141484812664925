<template>
    <div class="view-container">
        <!-- <div v-if="!checkCardsData" class="view-container__filter"> -->
                    <!-- <v-text-field :label="$t('form.inputs.search')" class="field-search" v-model="searchInput"></v-text-field> -->
                    <!-- <v-autocomplete :label="$t('form.inputs.filter-by-category')" :items="categoryList" variant="outlined"
                v-model="searchInput"></v-autocomplete> -->
                                <!-- <v-btn class="button-filter" @click="filter()">{{ $t("form.buttons.filter") }}</v-btn>
                                <v-btn class="button-reset" @click="resetInputs()">{{ $t("form.buttons.clean-filter") }}</v-btn> -->
                <!-- </div> -->
        <div v-if="!checkCardsData" class="view-container__filter">
            <div class="cards-filters">
                <v-text-field :label="$t('form.inputs.search')" class="field-search" @change="controlSubmitButton" v-model="searchInput"></v-text-field>
                <div class="calendar-inputs">
                    <v-text-field @change="controlSubmitButton" :min="dateLimits.currentDate" type="date" :label="$t('form.inputs.start-date')" v-model="startDate"></v-text-field>
                    <v-text-field @change="controlSubmitButton" :min="dateLimits.minEndDate" type="date" :label="$t('form.inputs.end-date')" v-model="endDate"></v-text-field>
                </div>
                <!-- <v-range-slider label="Precio (€)" v-model="sliderInput" thumb-label min="0" :max="maxPrice"></v-range-slider> -->
                <div class="buttons-container">
                    <v-btn class="button-filter" @click="handleFilterClick()" :disabled="toggleStateButton">{{$t("form.buttons.filter")}}</v-btn>
                    <v-btn class="button-reset" @click="resetInputs()">{{$t("form.buttons.clean-filter")}}</v-btn>
                </div>
            </div>
        </div>
        <p v-if="dateError" class="error-text">{{ textDateError }}</p>
            
        <div v-if="!checkCardsData && this.cardsData.length != 0" class="view-container__content">
            <div v-for="(item, index) in cardsData" :key="index">
                <HorizontalCard :cardData="item" />
            </div>
        </div>
        <div v-if="!checkCardsData && this.cardsData.length != 0">
            <PaginationComponent :page="actualPage" :totalPages="totalPages" @change-value="onChangeValue"></PaginationComponent>
        </div>
        <div v-if="checkCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
            <LoaderComponent></LoaderComponent>
        </div>
        <div v-if="checkLengthCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.not-found') }}</h2>
        </div>
    </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import { getInfoData } from "../services/serviceEndpoints";
import { mapState } from "vuex";
import { dateToTimestamp } from '../utils/utilsDate'
import { updateMetaTag } from "@/utils/utils";

const ITEMS_PER_PAGE = 50;

export default {
    components: {
        HorizontalCard,
        LoaderComponent,
        PaginationComponent
    },
    data() {
        return {
            cardsData: null,
            auxCardsData: null,
            searchInput: "",
            sliderInput: 0,
            maxPrice: 1,
            startDate: null,
            endDate: null, 
            dateError: false,
            toggleStateButton: true,
            textDateError: "",
            actualPage: 1,
            totalPages: 1,
            dateLimits: {
                currentDate: '',
                minEndDate: ''
            }
        };
    },
    async created() {
        localStorage.setItem("page", 0)
        this.sliderInput = [0, this.maxPrice]
        await this.getCardsData(this.language);
        this.$emit("changeHeight", true, true);
        this.calculateDateLimits();
    },
    beforeUnmount() {
        this.$emit("changeHeight", false, false);
    },
    methods: {
        async getCardsData(language) {
            localStorage.setItem("page", this.actualPage - 1)
            this.cardsData = await getInfoData("agenda", language, this.actualPage - 1, this.searchInput, "", this.toUnixTimestamp(this.startDate), this.toUnixTimestamp(this.endDate), ITEMS_PER_PAGE);
            this.cardsData.forEach(element => {
                element.price_filter = this.formaterPriceToFilter(element.price)

                if (parseInt(element.price_filter[1]) >= this.maxPrice) {
                    this.maxPrice = parseInt(element.price_filter[1])
                }
                element.price =  element.price != "Sin precio asignado" ? element.price : this.$t('cards.without-price')
                element.price = element.price.toUpperCase().trim() != "FREE" ? element.price : this.$t('cards.free')
                updateMetaTag('description', this.$t('descriptions.agenda'));
            });
            if (this.cardsData.length > 0) {
                this.totalPages = this.cardsData[0].pager.total_pages
            }
            
            if (this.searchInput == "") {
                this.auxCardsData = [...this.cardsData]
            }
        },
        controlSubmitButton(){
            if (this.startDate != null && this.startDate != "" && this.endDate != null && this.endDate != "") {
                this.toggleStateButton = false;
            }else if(this.searchInput != ""){
                this.toggleStateButton = false;
            }else{
                this.toggleStateButton = true;
            }
        },
        toUnixTimestamp(dateString) {
            if (!dateString) return null;
            return Math.floor(new Date(dateString).getTime() / 1000);
        },
        filterToUnixTimestamp(dateString) {
            const [day, month, year] = dateString.split('/').map(Number);
            const date = new Date(year, month - 1, day);
            return Math.floor(date.getTime() / 1000);
        },
        formaterPriceToFilter(price){
            let price_filter = []
            let priceArray = price.replace("€", "").replace(" ", "")
            priceArray = priceArray.split("/")
            if (priceArray.length == 1) price_filter = [priceArray[0], priceArray[0]]
            if (priceArray.length == 2) price_filter = [priceArray[0], priceArray[1]]
            if (priceArray.length > 2) price_filter = [priceArray[0], priceArray[priceArray.length-1]]
            if (price == "Sin precio asignado") {
                price_filter = [0, 0, "Sin precio"]

            }
            if (price == "Free") price_filter = [0,0]
            return price_filter
        },
        checkInpunts() {
            let correctInputs = true
            if (this.startDate != null && this.startDate != "" && this.endDate != null && this.endDate != "") {
                if (dateToTimestamp(this.startDate) > dateToTimestamp(this.endDate)) {
                    this.dateError = true
                    this.textDateError = this.$t('form.errors.start-date')
                    correctInputs = false
                } else {
                    this.dateError = false
                    this.textDateError = ""
                }
            } 
            return correctInputs
        },
        checkDateInputs() {
            if (this.startDate == null || this.startDate == "" || this.endDate == null || this.endDate == "") {
                this.dateError = true
                this.textDateError = this.$t('form.errors.fill-the-inputs')
                return false
            } else if (dateToTimestamp(this.startDate) > dateToTimestamp(this.endDate)) {
                this.dateError = true
                this.textDateError = this.$t('form.errors.start-date')
                return false
            } else {
                this.dateError = false
                this.textDateError = ""
                return true
            }
        },
        convertToISODate(dateStr){
            const [day, month, year] = dateStr.split('/');
            return `${year}-${month}-${day}`;
        },
        handleFilterClick() {
            if (this.searchInput) {
                this.filter();
            } else if (this.startDate && this.endDate) {
                this.filterByDate();
            }
        },
        filter() {
            if (this.searchInput == "") {
                this.cardsData = [...this.auxCardsData]
                return
            }
            this.cardsData = null
            this.onChangeValue(1)
        },
        filterByDate() {
            if (!this.checkDateInputs()) return
            this.cardsData = null
            this.onChangeValue(1)
            // this.cardsData = []
            // this.auxCardsData.forEach(element => {
            //     const formattedStartDate = this.startDate;
            //     const formattedElementDate = this.convertToISODate(element.time_start);
            
            //     if (dateToTimestamp(formattedStartDate) <= dateToTimestamp(formattedElementDate) || element.time_start === "") {
            //         this.cardsData.push(element);
            //     }
            // });
        },
        calculateDateLimits() {
            let today = new Date();
            let currentDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

            today.setDate(today.getDate() + 1);
            let minEndDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

            this.dateLimits.currentDate = currentDate;
            this.dateLimits.minEndDate = minEndDate;
        },
        resetInputs() {
            this.searchInput = ""
            this.startDate = ""
            this.endDate = ""
            this.filter()
            this.onChangeValue(1)
        },
        async onChangeValue(value) {
            this.actualPage = value
            this.cardsData = null
            await this.getCardsData(this.language);
        }
    },
    computed: {
        checkCardsData() {
            return this.cardsData == null;
        },
        checkLengthCardsData() {
            if (this.cardsData == null) return false;
            if (this.cardsData.length == 0) return true;
            return false;
        },
        ...mapState(["language"]),
    },
};
</script>

<style scoped>
.field-search {
    margin-right: 30px;
}
.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
}
.button-reset{
    margin-top: 2px;
    height: 50px;
}
.view-container {
    height: 100%;
    width: 100%;
}

.view-container__filter {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
}

.button-reset{
    margin-top: 2px;
    height: 50px;
}

.calendar-inputs{
    display: flex;
    gap: 20px;
}

.cards-filters {
    display: flex;
    width: 100%;
    gap: 20px;
}

.buttons-container{
    display: flex;
    gap: 20px;
    width: 25% !important;
}

.cards-filters div {
    width: 40%;
}

.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
}

.error-text {
    text-align: center;
    color: rgb(151, 0, 0);
}
.loading-info--loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

@media (max-width: 1880px) {
    .view-container__filter{
        width: 95%;
    }
}

@media (max-width: 1660px) {
    .field-search{
        width: 30% !important;
    }
    .calendar-inputs{
        width: 60% !important;
    }
}

@media (max-width: 1250px) {
    .cards-filters{
        display: block;
    }
    .cards-filters div{
        width: 100% !important;
        margin: 0 auto;
    }
}

@media (max-width: 890px) {
    .buttons-container{
        gap: 0;
    }
    .buttons-container button{
        width: 50%;
        font-size: 12px;
        height: 40px;
    }
    .buttons-container{
        display: flex;
        gap: 6px;
        padding: 0 15px;
    }
    .calendar-inputs{
        padding: 0 15px;
    }
}
@media (max-width: 800px) {
    .view-container__filter {
        flex-wrap: wrap;
    }
    .field-search {
        min-width: 150px;
    }
}

@media (max-width: 500px) {
    .view-container__filter{
        margin-top: 35px;
    }
    .view-container__filter {
        width: 90%;
    }
    .calendar-inputs{
        display: block;
    }
}
</style>
