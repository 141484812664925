<template>
    <div class="view-container">
        <div v-if="!checkCardsData" class="view-container__filter">
            <v-text-field :label="$t('form.inputs.search')" class="field-search" v-model="searchInput"></v-text-field>
            <v-btn class="button-filter" @click="filter()">{{ $t("form.buttons.filter") }}</v-btn>
            <v-btn class="button-reset" @click="resetInputs()">{{ $t("form.buttons.clean-filter") }}</v-btn>
        </div>
        <p v-if="dateError" class="error-text">{{ textDateError }}</p>

        <div v-if="!checkCardsData && this.cardsData.length != 0" class="view-container__content">
            <div v-for="(item, index) in getFilteredCards" :key="index">
                <HorizontalCard :cardData="item" />
            </div>
        </div>
        <div v-if="!checkCardsData && this.cardsData.length != 0">
            <PaginationComponent :page="actualPage" :totalPages="totalPages" @change-value="onChangeValue">
            </PaginationComponent>
        </div>
        <div v-if="checkCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
            <LoaderComponent></LoaderComponent>
        </div>
        <div v-if="checkLengthCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.not-found') }}</h2>
        </div>
    </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import { getInfoData } from "../services/serviceEndpoints";
import { mapState } from "vuex";
import { dateToTimestamp } from '../utils/utilsDate'
import { updateMetaTag } from "@/utils/utils";
export default {
    components: {
        HorizontalCard,
        LoaderComponent,
        PaginationComponent
    },
    data() {
        return {
            cardsData: null,
            auxCardsData: null,
            searchInput: "",
            startDate: "",
            endDate: "",
            dateError: false,
            textDateError: "",
            actualPage: 1,
            totalPages: 1,
        };
    },
    async created() {
        localStorage.setItem("page", 0)
        await this.getCardsData(this.language);
        this.$emit("changeHeight", true, true);
    },
    beforeUnmount() {
        this.$emit("changeHeight", false, false);
    },
    methods: {
        async getCardsData(language) {
            localStorage.setItem("page", this.actualPage - 1)
            this.cardsData = await getInfoData("culture", language, this.actualPage - 1, this.searchInput);
            if (this.cardsData.length > 0) {
                this.totalPages = this.cardsData[0].pager.total_pages
            }

            if (this.searchInput == "") {
                this.auxCardsData = [...this.cardsData]
            }
            updateMetaTag('description', this.$t('descriptions.culture'));
        },
        checkInpunts() {
            let correctInputs = true

            // Check date iputs
            if (this.startDate != null && this.startDate != "" && this.endDate != null && this.endDate != "") {
                if (dateToTimestamp(this.startDate) > dateToTimestamp(this.endDate)) {
                    this.dateError = true
                    this.textDateError = this.$t('form.errors.start-date')
                    correctInputs = false
                } else {
                    this.dateError = false
                    this.textDateError = ""
                }
            }

            //Return true si todo esta bien y false si algun campo esta mal
            return correctInputs
        },

        filter() {
            if (this.searchInput == "") {
                this.cardsData = [...this.auxCardsData]
                return
            }
            this.cardsData = null
            this.onChangeValue(1)
        },
        resetInputs() {
            this.searchInput = ""
            this.filter()
            this.onChangeValue(1)
        },
        async onChangeValue(value) {
            this.actualPage = value
            this.cardsData = null
            await this.getCardsData(this.language);
        }
    },
    computed: {
        checkCardsData() {
            return this.cardsData == null;
        },
        checkLengthCardsData() {
            if (this.cardsData == null) return false;
            if (this.cardsData.length == 0) return true;
            return false;
        },
        getFilteredCards() {
            if (this.cardsData == null) return []
            if (this.cardsData.length === 0) return
            return this.cardsData;
        },
        ...mapState(["language"]),
    },
};
</script>

<style scoped>
.field-search {
    margin-right: 30px;
}

.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}

.button-reset {
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}

.view-container {
    height: 100%;
    width: 100%;
}

.view-container__filter {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
}

.button-reset {
    margin-top: 2px;
    height: 50px;
}

.calendar-inputs {
    display: flex;
    gap: 20px;
}

.cards-filters {
    display: flex;
    width: 100%;
    gap: 20px;
}

.buttons-container {
    display: flex;
    gap: 20px;
}

.cards-filters div {
    width: 40%;
}

.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
}

.error-text {
    text-align: center;
    color: rgb(151, 0, 0);
}

.loading-info--loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

@media (max-width: 1880px) {
    .view-container__filter {
        width: 95%;
    }
}

@media (max-width: 1660px) {
    .field-search {
        width: 30% !important;
    }

    .calendar-inputs {
        width: 60% !important;
    }
}

@media (max-width: 1250px) {
    .cards-filters {
        display: block;
    }

    .cards-filters div {
        width: 100% !important;
        margin: 0 auto;
    }
}

@media (max-width: 890px) {
    .buttons-container {
        gap: 0;
    }

    .buttons-container button {
        width: 50%;
        font-size: 12px;
        height: 40px;
    }

    .buttons-container .button-filter {
        margin-right: 7px;
    }
}

@media (max-width: 800px) {
    .view-container__filter {
        flex-wrap: wrap;
    }

    .field-search {
        min-width: 150px;
        margin-left: 10px;
    }
}

@media (max-width: 500px) {
    .view-container__filter {
        margin-top: 35px;
    }

    .view-container__filter {
        width: 90%;
    }

    .calendar-inputs {
        display: block;
    }
}</style>
