<template>
  <div class="language-selector" :class="{ 'above-header': showAboveHeader }" v-if="!shouldHideLanguageSelector">
    <div class="dropdown">
      <button class="dropbtn" @click="showLanguageSeelctor()">
        <img :src="require(`@/assets/flags/${getSelectedLanguage}.png`)" :alt="getSelectedLanguage" height="15" width="20" class="flag" />
        {{ getSelectedLanguage }}
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="dropdown-content" v-if="isLanguageSelectorOpen">
        <a v-for="lang in supportedLanguages" :key="lang.code" @click="setLanguage(lang)">
          <img :src="require(`@/assets/flags/${lang.flag}.png`)" :alt="lang.lang" class="flag" />
          {{ lang.lang }}

          <v-icon style="color: black" v-if="lang.code == getSelectedLanguage">mdi-check</v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    showAboveHeader: Boolean, // Propiedad que indica si el selector debe mostrarse por encima del encabezado
  },
  data() {
    return {
      isLanguageSelectorOpen: false,
      selectedLanguage: localStorage.getItem("lang"),
      supportedLanguages: [
        { lang: "English", code: "en", flag: "en" },
        { lang: "Español", code: "es", flag: "es" },
        { lang: "Català", code: "ca", flag: "ca" },
        { lang: "Français", code: "fr", flag: "fr" },
        { lang: "Deutsch", code: "de", flag: "de" },
        { lang: "Русский", code: "ru", flag: "ru" },
      ],
      allowedRoutes: ["/info/activities", "/info/beaches", "/info/agenda", "/info/culture", "/info"]
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get('language');
    const hideLinksParam = params.get('hideLinks');
    if (langParam) {
      const lang = this.supportedLanguages.find(lang => lang.code === langParam);
      if (lang && hideLinksParam) {
        this.setLanguage(lang);
      }
    }
  },
  methods: {
    showLanguageSeelctor() {
      this.isLanguageSelectorOpen = !this.isLanguageSelectorOpen
    },
    setLanguage(language) {
      this.selectedLanguage = language.code;
      this.$i18n.locale = language.code;
      localStorage.setItem("lang", language.code);
      this.changeLanguage(language.code);
      if (this.$route.fullPath.includes("/info")) {
        window.location.reload();
      }
      this.showLanguageSeelctor()
    },
    ...mapActions(['changeLanguage']),
  },
  computed: {
    getSelectedLanguage() {
      const languageInfo = this.supportedLanguages.find(langObj => langObj.code === this.selectedLanguage);
      if (!languageInfo) {
        this.setLanguage(this.supportedLanguages[0])
      }
      return this.selectedLanguage
    },
    shouldHideLanguageSelector() {
      return this.$route.query.hideLinks === 'yes';
    },
  }
};
</script>

<style scoped>
.language-selector {
  display: flex;
  align-items: center;
  z-index: 5;
}

.language-selector.above-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Ajusta el valor según sea necesario */
  /* Estilos para el selector de idioma cuando está por encima del encabezado */
  /* Puedes personalizar estos estilos según tus necesidades */
}

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropbtn {
  text-align: left;
  background-color: #fbbf00;
  color: black;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  max-height: 44px;
  display: flex;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  position: absolute;
  background-color: #fbbf00;
  min-width: 160px;
  z-index: 1;
  border-radius: 10px;
}

.flag {
  width: 20px;
  height: 15px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 4px;
  float: left;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f7d66c;
  border-radius: 10px;
  
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Add a pointer cursor to dropdown button */
.dropbtn:hover {
  background-color: #fbbf00;
}
</style>
