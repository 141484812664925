<template>
  <div class="view-container">
    <div v-if="checkCardsData" class="loading-info--loading">
      <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
      <LoaderComponent></LoaderComponent>
    </div>
    <div v-if="checkLengthCardsData" class="loading-info--loading">
      <h2 class="loading-data-title">{{ $t('messages.not-found') }}</h2>
    </div>
    <div v-if="!checkCardsData && this.cardsData.length != 0" class="view-container__filter">
      <v-autocomplete :label="$t('form.inputs.filter-by-category')" :items="categoryList" variant="outlined"
        v-model="searchInput"></v-autocomplete>
    </div>
    <div v-if="!checkCardsData && this.cardsData.length != 0" class="view-container__content">
      <div v-for="(item, index) in getFilteredCards" :key="index">
        <HorizontalCard :cardData="item" />
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { getInfoData } from "../services/serviceEndpoints";
import { getContentType } from "../utils/utils";
import { mapState } from "vuex";
// import { getBeaches } from "../services/beaches"
export default {
  components: {
    HorizontalCard,
    LoaderComponent,
  },
  data() {
    return {
      cardsData: null,
      categoryList: ["Todos"],
      searchInput: "",
    };
  },
  async created() {
    await this.getCardsData(this.language);
    this.cardsData.forEach((element) => {
      if (!this.categoryList.includes(element.category)) {
        this.categoryList.push(element.category);
      }
    });
    this.$emit("changeHeight", true, true);
    this.getCardsData(this.language);
  },
  beforeUnmount() {
    this.$emit("changeHeight", false, false);
  },
  methods: {
    async getCardsData(language) {
      this.cardsData = await getInfoData(
        getContentType(this.$route.path),
        language
      );
    },
  },
  computed: {
    checkCardsData() {
      return this.cardsData == null;
    },
    checkLengthCardsData() {
      if (this.cardsData == null) return false;
      if (this.cardsData.length == 0) return true;
      return false;
    },
    getFilteredCards() {
      if (this.cardsData == null) {
        return [];
      }
      if (this.cardsData.length === 0) return;
      let filteredCards = [];
      if (this.searchInput == "" || this.searchInput == "Todos") {
        filteredCards = this.cardsData;
      }
      this.cardsData.forEach((element) => {
        if (element.category == this.searchInput) {
          filteredCards.push(element);
        }
      });
      return filteredCards;
    },
    ...mapState(["language"]),
  },
  watch: {
    language: {
      immediate: true, // Trigger the watcher immediately when the component is created
      handler(newLanguage) {
        // Use the new language to fetch data or perform any language-related tasks
        this.getCardsData(newLanguage);
      },
    },
  },
};
</script>

<style scoped>
.view-container {
  height: 100%;
  width: 100%;
}

.view-container__filter {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.loading-info--loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
</style>
