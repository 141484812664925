<template>
    <div class="view-container">
        <div v-if="!checkCardsData" class="view-container__filter">
            <v-text-field :label="$t('form.inputs.search')" class="field-search" v-model="searchInput"></v-text-field>
            <!-- <v-autocomplete :label="$t('form.inputs.filter-by-category')" :items="categoryList" variant="outlined" v-model="searchInput"></v-autocomplete> -->
            <v-btn class="button-filter" @click="filter()">{{ $t("form.buttons.filter") }}</v-btn>
            <v-btn class="button-reset" @click="resetInputs()">{{ $t("form.buttons.clean-filter") }}</v-btn>
        </div>
        <!-- <div v-if="!checkCardsData" class="view-container__filter">
            <div class="category-filters">
                <v-text-field type="date" :label="$t('form.inputs.start-date')" v-model="startDate" :min="today"></v-text-field>
                <v-text-field type="date" :label="$t('form.inputs.end-date')" v-model="endDate"></v-text-field>
                <div class="category-container">
                    <label for="category-toggle" class="category-label">{{ $t('form.filter-by-category') }}</label>
                    <input type="checkbox" id="category-toggle" v-model="categoryDropdownOpen" class="category-toggle">
                    <div v-if="categoryDropdownOpen" class="category-dropdown">
                        <ul class="category-list">
                            <template v-for="category in categoryList" :key="category.id">
                                <li>
                                <input type="radio" :id="'category-' + category.id" :value="category.id" v-model="selectedCategory" @change="filterByCategory"/>
                                <label class="parent-category"  :for="'category-' + category.id">{{ category.name }}</label>
                                    <ul v-if="category.children" class="subcategory-list">
                                        <li v-for="subCategory in category.children" :key="subCategory.id">
                                        <input
                                            type="radio"
                                            :id="'subcategory-' + subCategory.id"
                                            :value="subCategory.id"
                                            v-model="selectedCategory"
                                            @change="filterByCategory"
                                        />
                                        <label :for="'subcategory-' + subCategory.id">{{ subCategory.name }}</label>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div> 
            <div class="buttons-container">
                <v-btn class="button-filter" @click="filterByCategory()">{{$t("form.buttons.filter")}}</v-btn>
                <v-btn class="button-reset" @click="resetInputs()">{{$t("form.buttons.clean-filter")}}</v-btn>
            </div>
        </div> -->
        <p v-if="dateError" class="error-text">{{ textDateError }}</p>

        <div v-if="!isLoading && !checkCardsData && this.cardsData.length != 0" class="view-container__content">
            <div v-for="(item, index) in getFilteredCards" :key="index">
                <HorizontalCard :cardData="item" />
            </div>
        </div>
        <div v-if="!isLoading && !checkCardsData && this.cardsData.length != 0">
            <PaginationComponent :page="actualPage" :totalPages="totalPages" @change-value="onChangeValue"></PaginationComponent>
        </div>
        <div v-if="isLoading" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
            <LoaderComponent></LoaderComponent>
        </div>
        <div v-if="!isLoading && checkLengthCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.not-found') }}</h2>
        </div>
    </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import { getInfoData } from "../services/serviceEndpoints";
import { mapState } from "vuex";
import { dateToTimestamp } from '../utils/utilsDate'
import { updateMetaTag } from "@/utils/utils";
export default {
    components: {
        HorizontalCard,
        LoaderComponent,
        PaginationComponent
    },
    data() {
        return {
            cardsData: null,
            auxCardsData: null,
            startDate: "",
            endDate: "",
            today: '',
            dateError: false,
            textDateError: "",
            actualPage: 1,
            totalPages: 1,
            cacheCardsData: [],
            cacheCheckCardsData: [],
            searchInput: "",
            selectedCategory: "",
            categoryListCa: [
                {
                 id: 572,
                 name: `${this.$t('form.categories.gastronomy')}`,
                  children: [
                    { id: 593, name: `${this.$t('form.categories.restaurants')}` },
                    { id: 594, name: `${this.$t('form.categories.ice-creams')}` },
                    { id: 595, name: `${this.$t('form.categories.bars-and-cafes')}` },
                  ],
                },
                { id: 573, name: `${this.$t('form.categories.aquatic-activity')}` },
                { id: 575, name: `${this.$t('form.categories.health-wellness')}` },
                {
                 id: 593,
                 name: `${this.$t('form.categories.restaurants')}`,
                  children: [
                    { id: 596, name: `${this.$t('form.categories.german-cuisine')}` },
                    { id: 597, name: `${this.$t('form.categories.arabic-cuisine')}` },
                    { id: 598, name: `${this.$t('form.categories.argentina-cuisine')}` },
                    { id: 599, name: `${this.$t('form.categories.catalan-cuisine')}` },
                    { id: 600, name: `${this.$t('form.categories.author-cuisine')}` },
                    { id: 601, name: `${this.$t('form.categories.fish-and-seafood-cuisine')}` },
                    { id: 602, name: `${this.$t('form.categories.tapas-cuisine')}` },
                    { id: 603, name: `${this.$t('form.categories.dutch-cuisine')}` },
                    { id: 604, name: `${this.$t('form.categories.indian-cuisine')}` },
                    { id: 605, name: `${this.$t('form.categories.international-cuisine')}` },
                    { id: 606, name: `${this.$t('form.categories.italian-cuisine')}` },
                    { id: 607, name: `${this.$t('form.categories.japanese-cuisine')}` },
                    { id: 608, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
                    { id: 609, name: `${this.$t('form.categories.mexican-cuisine')}` },
                    { id: 610, name: `${this.$t('form.categories.fast-cuisine')}` },
                    { id: 611, name: `${this.$t('form.categories.chinese-cuisine')}` }
                  ],
                },
        ],
        categoryListEsp: [
            { id: 616, name: `${this.$t('form.categories.aquatic-activity')}` },
            { id: 617, name: `${this.$t('form.categories.health-wellness')}` },
            { id: 629, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
            { id: 630, name: `${this.$t('form.categories.gastronomy')}` },
        ],
        categoryListEn: [
            { id: 640, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
            { id: 641, name: `${this.$t('form.categories.gastronomy')}` },
            { id: 642, name: `${this.$t('form.categories.aquatic-activity')}` },
            { id: 643, name: `${this.$t('form.categories.health-wellness')}` },
        ],
        categoryListFr: [
            { id: 675, name: `${this.$t('form.categories.health-wellness')}` },
            { id: 676, name: `${this.$t('form.categories.aquatic-activity')}` },
            { id: 677, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
            { id: 678, name: `${this.$t('form.categories.gastronomy')}` },
        ],
        categoryListRu: [
            { id: 696, name: `${this.$t('form.categories.health-wellness')}` },
            { id: 697, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
            { id: 698, name: `${this.$t('form.categories.gastronomy')}` },
            { id: 699, name: `${this.$t('form.categories.aquatic-activity')}` },
        ],
        categoryListDe: [
            { id: 700, name: `${this.$t('form.categories.health-wellness')}` },
            { id: 701, name: `${this.$t('form.categories.mediterranian-cuisine')}` },
            { id: 702, name: `${this.$t('form.categories.gastronomy')}` },
            { id: 705, name: `${this.$t('form.categories.aquatic-activity')}` },
        ],
        categoryDropdownOpen: false,
        isLoading: false
        };
    },
    async created() {
        localStorage.setItem("page", 0)
        await this.getCardsData(this.language);
        this.$emit("changeHeight", true, true);
    },
    mounted() {
        this.today = new Date().toISOString().substr(0, 10);
    },
    beforeUnmount() {
        this.$emit("changeHeight", false, false);
    },
    methods: {
        async getCardsData(language) {
            this.isLoading = true;
            localStorage.setItem("page", this.actualPage - 1)
            await getInfoData("activity", language, this.actualPage - 1, this.searchInput, this.selectedCategory).then((result) => {
                
                this.cardsData = result;

                if (!this.cacheCheckCardsData.includes(this.actualPage - 1)) {
                    this.cacheCheckCardsData.push(this.actualPage - 1)
                    this.cardsData.forEach(element => {
                        this.cacheCardsData.push(element)
                    });
                }
            });
            if (this.cardsData.length > 0) {
                this.totalPages = this.cardsData[0].pager.total_pages 
            }
            
            if (this.searchInput == "") {
                this.auxCardsData = [...this.cardsData]
            }

            this.auxCardsData = [...this.cardsData]
            updateMetaTag('description', this.$t('descriptions.activities'));
            this.isLoading = false;
        },
        filterByCategory() {
            if (this.selectedCategory === "") {
                this.cardsData = [...this.auxCardsData];
            } else {
                this.getCardsData(this.language);
            }
            this.categoryDropdownOpen = false;
        },
        filterByDate() {
            if (!this.checkDateInputs()) return
            this.cardsData = []
            this.auxCardsData.forEach(element => {
                if ((dateToTimestamp(this.startDate) <= dateToTimestamp(element.created)) && (dateToTimestamp(this.endDate) >= dateToTimestamp(element.created))) {
                    this.cardsData.push(element)
                }
            });
        },
        checkDateInputs() {
            if (this.startDate == null || this.startDate == "" || this.endDate == null || this.endDate == "") {
                this.dateError = true
                this.textDateError = this.$t('form.errors.fill-the-inputs')
                return false
            } else if (dateToTimestamp(this.startDate) > dateToTimestamp(this.endDate)) {
                this.dateError = true
                this.textDateError = this.$t('form.errors.start-date')
                return false
            } else {
                this.dateError = false
                this.textDateError = ""
                return true
            }
        },
        filter() {
            if (this.searchInput == "") {
                this.cardsData = [...this.auxCardsData]
                return
            }
            this.cardsData = null
            this.onChangeValue(1)
        },
        resetInputs() {
            this.searchInput = ""
            this.selectedCategory = ""
            this.filter()
            this.onChangeValue(1)
        },
        async onChangeValue(value){
            this.actualPage = value
            this.cardsData = null
            await this.getCardsData(this.language);
        }
    },
    computed: {
        checkCardsData() {
            return this.cardsData == null;
        },
        checkLengthCardsData() {
            if (this.cardsData == null) return false;
            if (this.cardsData.length == 0) return true;
            return false;
        },
        getFilteredCards() {
            if (this.cardsData == null) return []
            if (this.cardsData.length === 0) return
            return this.cardsData;
        },
        categoryList() {
            switch (this.language) {
                case 'ca':
                    return this.categoryListCa;
                case 'es':
                    return this.categoryListEsp;
                case 'en':
                    return this.categoryListEn;
                case 'fr':
                    return this.categoryListFr;
                case 'de':
                    return this.categoryListDe;
                case 'ru':
                    return this.categoryListRu;
                default:
                    return [];
            }
        },
        ...mapState(["language"])
    },
};
</script>

<style scoped>
.field-search {
    margin-right: 30px;
}
.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}
.button-reset{
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}
.view-container {
    height: 100%;
    width: 100%;
}

.view-container__filter {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: right
}

.buttons-container{
    display: flex;
    gap: 20px;
}
.date-filters div{
    width: 30%;
    margin-left: 20px;
    margin-right: 20px;
}
.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
}
.button-reset{
    margin-top: 2px;
    height: 50px;
}
.error-text {
    text-align: center;
    color: rgb(151, 0, 0);
}
.loading-info--loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.category-list {
  list-style: none;
  padding: 0;
}

.subcategory-list {
  list-style: none;
  padding-left: 20px;
}

.category-list li {
  margin-bottom: 5px;
}

.subcategory-list li {
  margin-bottom: 2px;
}

.category-list input[type="radio"],
.subcategory-list input[type="radio"] {
  display: none;
}

.category-list label,
.subcategory-list label {
  cursor: pointer;
  width: 100%;
 display: block;
 margin-bottom: 5px;
 border-radius: 5px;
}

.category-list label:hover,
.subcategory-list label:hover {
    background-color: #dfdfdf;
}

.category-list label::before,
.subcategory-list label::before {
  visibility: hidden;
}
.category-toggle {
  display: none;
}

.category-label {
    cursor: pointer;
    background-color: #fbbf00;
    color: #fff;
    padding: 12px 30px;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-top: 8px;
    display: block;
    width: 290px;
    height: 45px;
    text-align: center;
}

.category-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

.category-list {
  list-style: none;
  padding: 0;
}

.subcategory-list {
  list-style: none;
  padding-left: 20px;
}

.category-list li {
  margin-bottom: 5px;
}

.subcategory-list li {
  margin-bottom: 2px;
}

.category-list label {
  cursor: pointer;
}

.category-list label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  border: 1px solid #ccc;
  border-radius: 50%;
  vertical-align: middle;
}

.category-list label.selected {
  font-weight: bold;
  color: black;
}
.parent-category{
    font-weight: bold;
}

@media (min-width: 1351px) {
    .view-container__filter{
        width: auto;
        max-width: 1240px;
    }
}
@media (max-width: 1350px) {
    .view-container__filter{
        width: 85%;
    }
}

@media (max-width: 890px) {
    .view-container__filter{
        width: 90% !important;
        display: block !important;
    }
    .buttons-container{
        width: 100%;
        justify-content: space-between;
        gap: inherit;
        align-items: center;
    }
    .category-filters{
        display: block;
    }
    .category-filters .category-label{
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }
    .buttons-container .button-filter, .buttons-container .button-reset{
        width: 100%;
        margin: 0;
    }
    .category-filters{
        margin-bottom: 20px;
    }
}
@media (max-width: 800px) {
    .field-search {
        min-width: 150px;
        margin-left: 10px;
    }
}

@media (max-width: 500px) {
    .view-container__filter {
        width: 95%;
    }
    .date-filters{
        display: block;
    }
    .date-filters div{
        margin: 0 auto;
        width: 95%;
    }
    .buttons-container .v-btn, .category-label{
        font-size: 12px;
        height: 40px;
    }
}
</style>
