import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router/router';
import i18n from './i18n';
import store from './store/index'; // Import your Vuex store here

loadFonts();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(i18n)
  .use(store) // Use your Vuex store
  .mount('#app');

// Registrar el Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(registration => {
      console.log('Service Worker registrado con éxito:', registration);
    }).catch(error => {
      console.log('Fallo al registrar el Service Worker:', error);
    });
  });
}
