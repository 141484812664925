<template>
  <div :style="{ backgroundImage: `url(${slide.path})` }" class="cardImage" @click="onMoreInfo()">
    <div class="overlay"></div>
    <div class="card-text">
      <h2>
        <strong>{{ slide.name }}</strong>
      </h2>
      <h3>{{ slide.desc }}</h3>
    </div>
    <div class="more-icon" @click="onMoreInfo()">
      <v-icon>mdi mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slide: Object,
  },
  methods: {
    onMoreInfo() {
      this.$router.push("/info/" + this.slide.link);
    },
  },
};
</script>

<style scoped>
.cardImage {
  cursor: pointer;
  position: relative;
  top: 0px;
  height: 31rem;
  width: 20rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.75);
}

.cardImage::before{
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 25%) 50%, rgb(0 0 0 / 39%) 100%);
  pointer-events: none;
  border-radius: 15px;
}

.card-text {
  position: absolute;
  bottom: 75px;
  left: 0;
  margin: -10px;
  color: white;
  width: 100%;
}

.card-text h2 {
  margin: 30px;
  text-align: left;
}

.card-text h3 {
  margin: 30px;
  margin-top: -25px;
  text-align: left;
  font-weight: 200;
  font-size: 15px;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 31rem;
  width: 100%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.2);
}

.more-icon {
  background-color: #fbbf00;
  height: 40px;
  width: 50px;
  position: absolute;
  bottom: 70px;
  right: -15px;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
}

.more-icon:hover {
  background-color: #fdda65;
}
@media (max-width: 1050px) {
    .cardImage {
    height: 26rem;
    width: 18rem;
  }
  .overlay {
    height: 26rem;
  }
}

@media (max-width: 999px) {
  .more-icon {
    right: -8px;
  }
}
@media (max-width: 450px) {
    .card-text {
      position: absolute;
      left: 0;
      margin: -4px;
      bottom: 70px;
    }
    .cardImage {
      height: 26rem;
      width: 13rem;
    }
    .card-text h2{
      margin: 10px 20px;
      font-size: 20px;
    }
}
</style>
