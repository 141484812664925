<template>
  <div class="term-of-use-view">
    <ShareBarComponent></ShareBarComponent>
    <div class="view-container__content">
        <div v-html="$t('pages.term-of-use-desc.desc')"></div>
    </div>
  </div>
</template>
  
<script>
import ShareBarComponent from "@/components/ShareBarComponent.vue";
export default {
  components: { ShareBarComponent },
  name: "TermOfUseView",
  data(){
    return{}
  }
};
</script>
  
<style>
.term-of-use-view{
  margin-bottom: 30px;
}
.term-of-use-view h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.term-of-use-view ul{
  padding-left: 2rem;
  margin-bottom: 30px;
}

.term-of-use-view ul li{
  margin-bottom: 10px;
}

.term-of-use-view p {
  margin-bottom: 27px;
}

.term-of-use-view p{
  line-height: 30px;
}
</style>