import { createI18n } from 'vue-i18n';

// Import your locale files
import en from '@/i18n/en.json';
import es from '@/i18n/es.json';
import ca from '@/i18n/ca.json';
import de from '@/i18n/de.json';
import fr from '@/i18n/fr.json';
import ru from '@/i18n/ru.json';

const messages = {
  en,
  es,
  ca,
  fr,
  de,
  ru
};

const i18n = createI18n({
  locale: 'ca', // Set the initial locale
  messages
});

export default i18n;
