export function dateToTimestamp(dateString) { //dateString tiene que ser con el formato (YYYY-MM-DD)
    if (dateString == null || dateString=="") return null
    const parts = dateString.split('-');
    if (parts.length === 3) {
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1; // Los meses en JavaScript son de 0 a 11
        const day = parseInt(parts[2]);
        const timestamp = new Date(year, month, day).getTime()/1000; // Obtiene el timestamp en milisegundos
        return timestamp;
    }
    return null; // Retorna null si el formato de fecha no es válido
}