<template>
  <div v-if="checkCardsData" class="loading-info--loading">
    <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
    <LoaderComponent></LoaderComponent>
  </div>
  <div v-else class="view-container">
     <ShareBarComponent></ShareBarComponent>
    <div v-if="!contentNoAvailable" class="title-container">
      <h1>{{ descContent.title ? decodeHtml(descContent.title) : '-'  }}</h1>
    </div>
    <div v-if="!contentNoAvailable"  class="date-container">
      <v-icon>mdi-clock</v-icon>
      <h3 v-if="this.extractSegmentFromURL(this.$route.path) == 'beaches'">{{ $t('beaches-services.title') }}: </h3>
      <div class="date-container__date">
        <h3>{{ descContent.opening_date }}</h3> -<h3>{{ descContent.closing_date }}</h3>
      </div>
    </div>
    <div class="desc-text">
      <div v-html="trustedHtml"></div>
    </div>

    <div v-if="this.extractSegmentFromURL(this.$route.path) == 'beaches'" class="info-beaches-services-and-activities">
      <div class="services">
        <span><b>{{ $t("cards.services") }}: </b></span>{{ descContent.services ? descContent.services : '-' }}<span></span>
      </div>
      <div class="activities">
        <span><b>{{ $t("cards.activities") }}: </b></span>{{ descContent.activities ? descContent.activities : '-'}}<span></span>
      </div>
    </div>

    <div v-if="this.extractSegmentFromURL(this.$route.path) == 'beaches'" class="info-container">
      <p><strong><v-icon icon="mdi-beach"></v-icon> {{ $t('cards.sand') }}:</strong> {{descContent.sand ?
        descContent.sand : '-'}}</p>
      <p><strong><v-icon icon="mdi-ruler"></v-icon> {{ $t('cards.length') }}:</strong> {{descContent.length ?
        descContent.length : '-' }}</p>
      <p><strong><v-icon icon="mdi-ruler"></v-icon> {{ $t('cards.width') }}:</strong> {{descContent.width ?
        descContent.width : '-' }}</p>
      <p><strong><v-icon icon="mdi-slope-uphill"></v-icon> {{ $t('cards.slope') }}:</strong> {{descContent.slope ?
        descContent.slope : '-' }}</p>
    </div>

    <v-btn v-if="descContent.location_latitude != '' && descContent.location_longitude != '' && !shouldHideButton && !contentNoAvailable" class="call-btn" @click="goToMaps()">{{ $t('form.buttons.call-action') }}</v-btn>

  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import ShareBarComponent from "@/components/ShareBarComponent.vue";
import { getCurrentInfoData } from "../services/serviceEndpoints";
import { getContentType, updateMetaTag, ariaLabelTransform } from "../utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    LoaderComponent, ShareBarComponent
  },
  data: () => ({
    cardData: null,
    contentId: "",
    descContent: {},
    contentData: {},
    htmlString: "",
    metaTags: [],
    imageLink: "",
    api: "https://cms.lloret.cat",
    contentNoAvailable: false
  }),
  created() {
    this.$emit("isCarouselHeader", true);
    this.getCardsData(this.language);
  },
  mounted() {
    this.checkIsMobile();
  },
  beforeUnmount() {
    this.$emit("isCarouselHeader", false);
    window.removeEventListener("resize", this.checkIsMobile);
    this.removeSocialNetworkTags();
  },
  computed: {
    shouldHideButton() {
      return this.$route.query.hideLinks === 'yes';
    },
    trustedHtml() {

      if (!this.htmlString || this.htmlString.trim() === '') {
        return `<h3 style="text-align: center; padding: 20px 0;">${this.$t('no-data-content-language-message')}</h3>`
      }

      const decodedString = document.createElement('textarea');
      decodedString.innerHTML = this.htmlString;

      const decodedStringValue = decodedString.value 
        ? decodedString.value 
        : this.$t('no-data-content-language-message');

      return decodedStringValue;
    },
    checkCardsData() {
      return this.cardData == null
    },
    isMobile() {
      return window.innerWidth < 1000;
    },
    ...mapState(["language"])
  },
  methods: {
    goToMaps() {
      window.open(`https://www.google.com/maps?q=${this.descContent.location_latitude},${this.descContent.location_longitude}`);
    },
    getIdFromRoute() {

      const fullPath = this.$route.fullPath;
      const regex = /id=(\d+)/;
      const match = fullPath.match(regex);

      return match ? match[1] : null;
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 1000;
    },
    updateContentAvailability() {
      if (!this.htmlString || this.htmlString.trim() === '') {
        this.contentNoAvailable = true;
      } else {
        this.contentNoAvailable = false;
      }
    },
    decodeHtml(htmlString) {
      const intermediateDecoding = document.createElement('div');
      intermediateDecoding.innerHTML = htmlString;
      const partiallyDecodedString = intermediateDecoding.innerText || intermediateDecoding.textContent || '';
            
      const finalDecoding = document.createElement('div');
      finalDecoding.innerHTML = partiallyDecodedString;
      return finalDecoding.innerText || finalDecoding.textContent || '';
    },
    async getCardsData(language) {

      if (language) {

        this.contentId = this.getIdFromRoute();
        this.cardData = await getCurrentInfoData(getContentType("/info/" + this.extractSegmentFromURL(this.$route.path)), language, this.contentId);

        for (let card of this.cardData) {
          this.descContent = card;
          this.imageLink = card.images;
          this.setSocialNetworkTags();
        }

        // this.descContent.location_address =
        //   this.descContent.location_address.replace(/&#039;/g, "'");
        // this.descContent.description = this.splitTextAndExtractImage(
        //   this.descContent.description
        // );
        
        this.htmlString = this.descContent.content;
        this.updateContentAvailability();
        updateMetaTag('description', this.descContent.title);
      }
    },
    splitTextAndExtractImage(text) {
      // Regular expression to match <img> tags and extract the last src value
      const imgRegex = /<img[^>]*src=["'](https:\/\/[^"']+)["'][^>]*>/i;

      // Use regular expression to extract the image source
      // Remove the image tag from the text
      const content = text.replace(imgRegex, "");

      return content;
    },
    extractSegmentFromURL(inputURL) {
      const regex = /\/info\/([^/]+)/;
      const match = inputURL.match(regex);

      if (match && match[1]) {
        return match[1];
      }

      return "";
    },
    setAriaLabel(text){
      return ariaLabelTransform(text)
    },
    setSocialNetworkTags(){
      const ogImage = `https://cms.lloret.cat${this.imageLink}`
      this.metaTags.push({ label: 'title', name: 'og:title', content: `App Lloret de Mar - ${this.descContent.title}` });
      this.metaTags.push({ label: 'type', name: 'og:type', content: 'website' });
      this.metaTags.push({ label: 'image', name: 'og:image', content: ogImage});
      this.metaTags.push({ label: 'url', name: 'og:url', content: window.location.href });
      this.metaTags.forEach(tag => {
        const meta = document.createElement('meta');
        meta.setAttribute('name', tag.label);
        meta.setAttribute('property', tag.name);
        meta.setAttribute('content', tag.content);
        document.head.appendChild(meta);
      });
    },
    removeSocialNetworkTags(){
      this.metaTags.forEach(tag => {
        const meta = document.querySelector(`meta[property="${tag.name}"]`);
        if (meta) {
          meta.parentNode.removeChild(meta);
        }
    });
    }
  }
};
</script>

<style scoped>
.view-container {
  width: 100%;
  height: 100%;
}

.date-container__date{
  display:flex;
  align-items: center;
  justify-content:left;
}

.info-beaches-services-and-activities{
  padding: 0px 40px;
}

.info-beaches-services-and-activities .services, .info-beaches-services-and-activities .activities{
  margin-bottom: 15px;
}

.title-container {
  margin: 30px;
}
.title-container h1 {
  color: #343a40;
  font-size: 32px;
}

.title-container h3 {
  font-size: 14px;
  color: #343a40;
  text-transform: uppercase;
}

#icon-button {
  border: 3px solid #6c757d;
  color: #6c757d;
  transform: scale(0.75);
}

.location-container {
  height: 60px;
  width: 60%;
  margin: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.date-container {
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 20px;
}

.date-container h3 {
  margin-left: 10px;
}

.desc-text {
  margin: 10px 30px 15px 30px;
  text-align: justify;
  width: 90%;
  padding: 10px;
}

.info-container{
  margin:30px;
  padding:10px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:90%;
}

.call-btn {
  background-color: #fbbf00;
  margin: 30px;
  color: white;
  border-radius: 10px;
  box-shadow: none;
}

.call-btn:hover {
  background-color: #fdd141;
}

.desc-text p {
  margin-top: 20px;
}

.location-div h3 {
  margin-left: 10px;
}

.location-div {
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 20px;
}
.loading-info--loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

@media (max-width: 760px) and (min-width: 300px) {
  .location-container {
    height: 60px;
    width: 80%;
    margin: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 550px) {
  .date-container h3{
    font-size: 15px;
  }
  .date-container i{
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .title-container{
    margin: 0;
  }
  .title-container h1{
    padding: 20px 15px;
  }
  .info-container{
    width: 100%;
    display: block;
    margin: 0;
    padding: 10px 15px;
  }
  .text-share{
    display: none;
  }
  .info-beaches-services-and-activities{
    padding: 0 10px;
  }
  .desc-text{
    margin: 15px 0;
    width: 100%;
    padding: 0px 15px;
  }
  #icon-button {
    border: 3px solid #6c757d;
    color: #6c757d;
    transform: initial;
    height: 29px;
    width: 29px;
  }
  .call-btn{
    margin: 30px 15px;
  }
  .date-container{
    display: block;
  }
  .date-container h3{
    display: inline-block;
  }
  .date-container__date{
    display: inline-block;
  }
}

</style>
