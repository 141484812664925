<template>
  <div class="share-bar">
    <v-btn
      class="back-icon"
      :aria-label="$t('header.back-button')"
      icon
      @click="onGoBack"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="icon-container" v-if="!shouldHideShareBar">
      <h3 class="text-share">{{ $t("share.title") }}</h3>
      <div class="icon-inner-container">
        <img
          v-for="icon in icons"
          :src="require(`@/assets/social-networks/${icon.icon}`)"
          :alt="`${$t('share.title')} ${icon.name}`"
          @click="shareOnSocialMedia(icon.name)"
          :key="icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareBarComponent",
  data: () => ({
    icons: [
      {
        name: 'Twitter',
        icon: 'x-twitter.svg'
      },
      {
        name: 'Facebook',
        icon: 'facebook.svg'
      },
      {
        name: 'Linkedin',
        icon: 'linkedin.svg'
      }
    ],
    currentURL: ""
  }),
  created(){
    this.currentURL = encodeURIComponent(window.location.href);
  },
  computed: {
    shouldHideShareBar() {
      return this.$route.query.hideLinks === 'yes';
    }
  },
  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
    shareOnSocialMedia(icon) {
      switch (icon) {
        case "Twitter":
          // Abre Twitter en una nueva ventana o pestaña con la URL actual
          window.open(`https://twitter.com/intent/tweet?url=${this.currentURL}`);
          break;
        case "Facebook":
          // Abre Facebook en una nueva ventana o pestaña con la URL actual
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${this.currentURL}`
          );
          break;
        case "Linkedin":
          // Abre LinkedIn en una nueva ventana o pestaña con la URL actual
          window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.currentURL}`);
          break;
        default:
          break;
      }
    },
  }
};
</script>

<style scoped>

.icon-inner-container{
  display: flex;
  gap: 40px;
  padding: 0 20px;
}

.icon-inner-container img{
  width: 20px;
  cursor: pointer;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share-bar {
  background-color: #f8f9fa;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.back-icon {
  border: 2px solid #e7eaed;
  transform: scale(0.7);
  background-color: white;
  color: #e9ecef;
}

.text-share {
  color: #6c757d;
  text-transform: uppercase;
}
@media (max-width: 760px) and (min-width: 300px) {
  .share-bar {
    background-color: #f8f9fa;
    height: 60px;
    width: 100%;
  }
  .back-icon {
    border: 2px solid #e7eaed;
    transform: initial;
    background-color: white;
    color: #e9ecef;
    width: 30px;
    height: 30px;
  }

  .text-share {
    color: #6c757d;
    font-size: 16px;
    text-transform: uppercase;
  }


  .icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 500px) {
  .share-bar {
    justify-content: normal;
  }
}
</style>