<template>
  <div class="not-found-view">
    <div class="content">
        <h1>{{ $t('pages.404.title') }}</h1>
        <p>{{ $t('pages.404.desc') }}</p>
        <div class="loading-info--loading">
            <LoaderComponent></LoaderComponent>
        </div>
        <v-btn class="button" @click="this.$router.push('/')">
            {{ $t('pages.404.button') }}
        </v-btn>
    </div>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
export default {
  name: 'NotFoundView',
  components: {LoaderComponent}
}
</script>

<style scoped>
.not-found-view{
    text-align: center;
    position: relative;
    margin: 30px 0;
    height: 480px;
}

.not-found-view .content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
}

h1{
    font-size: 36px;
    margin-bottom: 10px;
}

p{
    margin-bottom: 20px;
}

.loading-info--loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.button {
    background-color: #fbbf00;
    color: #000;
    margin-top: 25px;
    height: 50px;
}

@media (max-width: 639px) {
    .not-found-view .content{
        position: static;
        transform: initial;
        padding: 0 15px;
    }
    h1{
        font-size: 27px;
    }
}

</style>