<template>
      <CarouselComponent :key="$i18n.locale" />
</template>

<script>
import CarouselComponent from "@/components/CarouselComponent.vue";
import { updateMetaTag } from "@/utils/utils";
export default {
  components: {
    CarouselComponent,
  },
  created(){
    updateMetaTag('description', this.$t('descriptions.home'));
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
</style>
