<template>
    <div class="view-container">
            <div v-if="!checkCardsData" class="view-container__filter">
                <v-text-field :label="$t('form.inputs.search')" class="field-search" v-model="searchInput"></v-text-field>
                <!-- <v-autocomplete :label="$t('form.inputs.filter-by-category')" :items="categoryList" variant="outlined"
                v-model="searchInput"></v-autocomplete> -->
                <v-btn class="button-filter" @click="filter()">{{ $t("form.buttons.filter") }}</v-btn>
                <v-btn class="button-reset" @click="resetInputs()">{{ $t("form.buttons.clean-filter") }}</v-btn>
            </div>
        <div v-if="checkCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.loading') }}</h2>
            <LoaderComponent></LoaderComponent>
        </div>
        <div v-if="checkLengthCardsData" class="loading-info--loading">
            <h2 class="loading-data-title">{{ $t('messages.not-found') }}</h2>
        </div>
        <div v-if="!checkCardsData && this.cardsData.length != 0" class="view-container__content">
            <div v-for="(item, index) in this.cardsData" :key="index">
                <HorizontalCard :cardData="item" :cardImage="item.imageUrl" />
            </div>
        </div>
        <div v-if="!checkCardsData && this.cardsData.length != 0">
            <PaginationComponent :page="actualPage" :totalPages="totalPages" @change-value="onChangeValue"></PaginationComponent>
        </div>
    </div>
</template>

<script>
import HorizontalCard from "@/components/HorizontalCard.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import { getInfoData } from "../services/serviceEndpoints";
import { mapState } from "vuex";
import { updateMetaTag } from "@/utils/utils";
export default {
    components: {
        HorizontalCard,
        LoaderComponent,
        PaginationComponent
    },
    data() {
        return {
            api: "https://cms.lloret.cat",
            cardsData: null,
            auxCardsData: null,
            categoryList: [this.$i18n.t('form.inputs.all')],
            searchInput: "",
            actualPage: 1,
            totalPages: 1
        };
    },
    async created() {
        localStorage.setItem("page", 0)
        await this.getCardsData(this.language);
        this.pathName = this.$route.path.split("/info/")[1];
        this.cardsData.forEach((element) => {
            if (!this.categoryList.includes(element.category)) {
                this.categoryList.push(element.category);
            }
        });
        this.$emit("changeHeight", true, true);
        updateMetaTag('description', this.$t('descriptions.beaches'));
    },
    beforeUnmount() {
        this.$emit("changeHeight", false, false);
    },
    methods: {
        async getCardsData(language) {
            localStorage.setItem("page", this.actualPage - 1)
            this.cardsData = await getInfoData("platja", language, this.actualPage - 1, this.searchInput);
            if (this.cardsData.length > 0) {
                this.totalPages = this.cardsData[0].pager.total_pages 
            }
            if (this.searchInput == "") {
                this.auxCardsData = [...this.cardsData]
            }

            let beachImages = await getInfoData("platja", 'ca', this.actualPage - 1, this.searchInput);
            let imageMap = {};

            beachImages.forEach((element) => {
                imageMap[element.id] = this.api + element.images;
            });

            this.cardsData = this.cardsData.map(card => {
                return {
                    ...card,
                    imageUrl: imageMap[card.id] || ''
                }
            });
        },
        async onChangeValue(value) {
            this.actualPage = value
            this.cardsData = null
            await this.getCardsData(this.language);
        },
        filter() {
            if (this.searchInput == "") {
                this.cardsData = [...this.auxCardsData]
                return
            }
            this.cardsData = null
            this.onChangeValue(1)
        },
        resetInputs() {
            this.searchInput = ""
            this.filter()
            this.onChangeValue(1)
        }
    },
    computed: {
        checkCardsData() {
            return this.cardsData == null;
        },
        checkLengthCardsData() {
            if (this.cardsData == null) return false;
            if (this.cardsData.length == 0) return true;
            return false;
        },
        ...mapState(["language"]),
    },
};
</script>

<style scoped>
.view-container {
    height: 100%;
    width: 100%;
}

.view-container__filter {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
}

.loading-info--loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.field-search {
    margin-right: 30px;
}
.button-filter {
    background-color: #fbbf00;
    color: white;
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}
.button-reset{
    margin-top: 2px;
    height: 50px;
    margin-left: 10px;
}
@media (max-width: 800px) {
    .view-container__filter {
        flex-wrap: wrap;
    }
    .field-search {
        min-width: 150px;
        margin-left: 10px;
    }
}

@media (max-width: 500px) {
    .view-container__filter {
        width: 80%;
    }
}
</style>
