import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import InfoView from '@/views/InfoView.vue';
import DescView from '@/views/DescView.vue';
import ActivitiesView from '@/views/ActivitiesView.vue';
import AgendaView from '@/views/AgendaView.vue';
import BeachesView from '@/views/BeachesView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import CultureView from '@/views/CultureView.vue';
import TermOfUseView from '@/views/TermOfUseView.vue';


const routes = [
  { path: '/', name: "HomeView",  component: HomeView },
  { path: '/info/:link', name: "InfoView", component: InfoView, props: true },
  // { path: '/info/beaches', component: BeachesView },
  { path: '/info/activities', name: "ActivitiesView", component: ActivitiesView },
  { path: '/info/agenda', name: "AgendaView", component: AgendaView },
  { path: '/info/beaches', name: "BeachesView", component: BeachesView },
  { path: '/info/culture', name: "CultureView", component: CultureView },
  { path: '/info/:link/:desc-id=:id', name: "DescView", component: DescView, props: true },
  { path: '/term-of-use', name: "TermOfUseView", component: TermOfUseView },
  { path: '/:catchAll(.*)', component: NotFoundView }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
