<template>
  <v-dialog v-model="showModal" width="auto">
    <v-card>
      <v-img cover class="img-zoom" :src="currentImage"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
  }),
  props: {
    isModal: Boolean,
    currentImage: String,
  },
  watch: {
    isModal() {
      this.showModal = this.isModal;
    },
    showModal() {
      if (this.showModal === false) {
        this.$emit("close", this.showModal);
      }
    },
  },
  beforeUnmount() {
    this.showModal = false;
  },
};
</script>

<style scoped>
.img-zoom {
  height: 80vh;
  width: 80vw;
}
</style>
