<template>
  <div app name="footer">
    <div class="footer-component">
      <!-- <div class="social-media-container">
        <img v-for="icon in icons" :src="require(`@/assets/social-networks/${icon.icon}`)" :alt="icon.name"
          @click="goToLink(icon.name)" :key="icon" />
      </div> -->

      <div class="social-media-container">
        <div v-if="!shouldHideIcons">
          <a v-for="icon in icons" :href="icon.link" :key="icon" target="blank">
            <img :src="require(`@/assets/social-networks/${icon.icon}`)" :alt="icon.name" />
          </a>
        </div>
      </div>

      <!-- <div>
        <v-btn class="mx-4" id="icon-button-info" aria-label="Info" icon="mdi-information-variant" variant="text" @click="downloadPDF()"></v-btn>
      </div> -->

      <div class="term-and-conditions-container">
        <router-link :to="!shouldHideIcons ? '/term-of-use' : `/term-of-use?hideLinks=yes${langParam ? `&language=${langParam}`: ''}` ">{{ $t('pages.term-of-use') }}</router-link>
      </div>

    </div>
    <div class="logos-lloret">
      <img class="logos-lloret__logo logo1" :src="require('@/assets/gb-red.webp')"
        :alt="`${$t('logos.footer.2')} - ${$t('logos.footer.3')}`" height="250" width="450">
      <img class="logos-lloret__logo logo1" :src="require('@/assets/ayuntamiento-lloret.webp')"
        :alt="$t('logos.footer.1')" height="200" width="120">
      <img class="logos-lloret__logo logo4" :src="require('@/assets/udensXa.webp')" :alt="$t('logos.footer.4')"
        height="150" width="120">
    </div>
  </div>
</template>

<script>
import { ariaLabelTransform } from "@/utils/utils";

export default {
  data: () => ({
    icons: [
      {
        name: 'Instagram',
        icon: 'instagram.svg',
        link : "https://www.instagram.com/lloretturisme/"
      },
      {
        name: 'Twitter',
        icon: 'x-twitter.svg',
          link : "https://twitter.com/lloretturisme"
      },
      {
        name: 'Facebook',
        icon: 'facebook.svg',
          link : "https://www.facebook.com/Lloretturisme"
      },
      {
        name: 'Youtube',
        icon: 'youtube.svg',
          link : "https://www.youtube.com/lloretturisme"
      },
      {
        name: 'Tik Tok',
        icon: 'tiktok.svg',
          link : "https://www.tiktok.com/@lloretturisme"
      },
      {
        name: 'Linkedin',
        icon: 'linkedin.svg',
          link : "https://www.linkedin.com/company/lloret-turisme"
      }
    ],
    langParam: ''
  }),
  computed: {
    shouldHideIcons() {
      return this.$route.query.hideLinks === 'yes';
    },
  },
  mounted(){
    const params = new URLSearchParams(window.location.search);
    this.langParam = params.get('language');
  },
  methods: {
    setAriaLabel(text){
      return ariaLabelTransform(text)
    },
    goToLink(icon) {
      let link;
      switch (icon) {
        case "Instagram":
          link = "https://www.instagram.com/lloretturisme/";
          break;
        case "Twitter":
          link = "https://twitter.com/lloretturisme";
          break;
        case "Facebook":
          link = "https://www.facebook.com/Lloretturisme";
          break;
        case "Youtube":
          link = "https://www.youtube.com/lloretturisme";
          break;
        default:
          link = "#";
      }
      window.open(link, "_blank");
    },
    // downloadPDF(){
    //   const pdfPath = '/lloret.pdf';
    //   window.open(pdfPath, '_blank');
    // }
  }
};
</script>

<style scoped>

.footer-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #f8f9fa;
}

.social-media-container{
  margin-left: 15px;
}

.social-media-container img{
  height: 45px;
  border: 3px solid #6c757d;
  color: #6c757d;
  transform: scale(.75);
  border-radius: 50%;
  padding: 10px;
  width: 45px;
  cursor: pointer;
}

.icon-button {
  border: 3px solid #6c757d;
  color: #6c757d;
  transform: scale(0.75);
}

#icon-button-info {
  color: #6c757d;
  background-color: #e2e6ea;
  transform: scale(0.75);
}
.logos-lloret {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8F9FA;
}
.logos-lloret img:first-child{
  padding: 0px;
}
.logos-lloret__logo {
  width: 25%;
  margin: 10px;
  padding: 30px;
  object-fit: contain;
}
.logo2 {
  width: 15%;
}

.term-and-conditions-container a{
  text-decoration: none;
  padding-right: 15px;
  color: #000;
  font-size: 15px;
}

@media (max-width: 1850px){
  .logos-lloret img:first-child{
    width: 35%;
  }
}

@media (max-width: 1150px){
  .logos-lloret__logo{
    width: 40% !important;
    padding: 10px;
  }
  .logos-lloret {
  flex-wrap: wrap;

  }
}
@media (max-width: 639px){
  .logos-lloret{
    padding-top: 15px;
  }
  .logos-lloret__logo{
    width: 80% !important;
    height: 100%;
  }
}
@media (max-width: 500px){
  .social-media-container img{
    transform: scale(.85);
    padding: 4px;
    width: 34px;
    height: 34px;
  }
  .footer-component{
    padding-top: 20px;
  }
  .logos-lloret{
    padding-top: 70px;
  }
  .term-and-conditions-container a{
    font-size: 13px;
  }
}
@media (max-width: 400px){
  .logos-lloret__logo{
    width: 90% !important;
    padding: 20px;
  }
  .term-and-conditions-container{
    max-width: 110px;
  }
}

</style>
