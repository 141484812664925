<template>
  <v-dialog v-model="showDialog" v-if="notificationsList.length > 0" width="auto" scroll-strategy="none" persistent content-class="notifications-dialog">
    <v-card>
      <div class="title-container">
        <v-card-title>{{ this.$i18n.t('notifications.title') }}</v-card-title>
        <v-btn class="close-notifications" @click="hideDialog"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="content-container">
        <v-card-text v-for="(notification, index) in notificationsList" :key="index">
          <span>{{ notification.title }}</span>
          <p>{{ notification.description }}</p>
          <a :href="notification.url" target="_blank">{{ notification.url }}</a>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {getNotifications} from '@/services/notifications'
export default {
  name: "NotificationComponent",
  data() {
    return {
      showDialog: false,
      notificationsList: [],
      currentDate: ''
    };
  },
  created(){
    this.getNotificationsData();
    this.checkNotificationVisibility();
    this.getCurrentDate();
  },
  watch: {
    '$i18n.locale'() {
      this.hideDialog();
    }
  },
  methods: {
    getNotificationsData(){
      getNotifications(this.$i18n.locale)
        .then((result) => {
          this.notificationsList = result.data.rows.filter(notification => {
            return notification.date.split("T")[0] === this.currentDate;
          });
          if (this.notificationsList.length > 0) {
            this.showDialog = true;
          }
      });
    },
    checkNotificationVisibility(){
      const lastShownTime = localStorage.getItem('lastShownTime');
      if (!lastShownTime || Date.now() - parseInt(lastShownTime) > 86400000) {
         this.showDialog = true;
      }
      this.currentDate = localStorage.getItem('currentDate') || '';
    },
    getCurrentDate(){
      const date = new Date(Date.now());
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      localStorage.setItem('currentDate',`${year}-${month}-${day}`);
    },
    hideDialog() {
      if (this.showDialog) {
        localStorage.setItem('lastShownTime', Date.now().toString());
        this.showDialog = false;
      }
    },
  }
};
</script>

<style>
.notifications-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
}
.notifications-dialog .v-card-text{
  padding: 10px 15px 10px !important;
  border-bottom: 1px solid #ccc;
}
.notifications-dialog .v-card-text:last-child{
  border-bottom: 0px !important;
}
.notifications-dialog .v-card-title{
  background-color: #fbbf00;
}
.v-overlay__scrim{
    display: none;
}
.notifications-dialog .v-card-text span{
  font-size: 15px;
  font-weight: 500;
}
.notifications-dialog .v-card-text p{
  font-size: 13px;
}
.notifications-dialog .v-card-text a{
  font-size: 13px;
  text-decoration: none;
}
.notifications-dialog .close-notifications{
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 3;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  min-width: 35px;
}
.notifications-dialog .content-container{
  max-height: 250px;
}
</style>
