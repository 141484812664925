// import { getTextFromATag } from "../utils/utils";
import ServiceEndpoints from "./serviceEndpoints";

async function fetchAllPagesAgenda(lang, title_filter, category_filter, time_start_min, time_start_max, items_per_page) {
    let allData = [];
    let currentPage = 0;
    let totalPages = 1;

    while (currentPage < totalPages) {
        const result = await ServiceEndpoints.listOfContent("agenda", lang, currentPage, title_filter, category_filter, time_start_min, time_start_max, items_per_page);
        
        const currentDateTimestamp = Math.floor(Date.now() / 1000);

        const filteredAgendas = result.data.rows.filter(event => {
            const startTime = event.period_start ? filterToUnixTimestamp(event.period_start) : null;
            const endTime = event.period_end ? filterToUnixTimestamp(event.period_end) : null;

            const eventStartTime = event.time_start ? filterToUnixTimestamp(event.time_start) : null;
            

            const condition1 = !startTime && !endTime && !eventStartTime;
            const condition2 = endTime >= currentDateTimestamp;
            const condition3 = eventStartTime >= currentDateTimestamp;
            return condition1 || condition2 || condition3;
        });

        allData = allData.concat(filteredAgendas);
        totalPages = result.data.pager.total_pages;
        currentPage++;
    }

    return allData;
}

export async function getAgenda(lang, page, title_filter, category_filter, time_start_min, time_start_max, items_per_page) {
    const regex = /<img.*?src=['"](.*?)['"]/;
    let allAgendas = [];

    // Obtener todos los datos de todas las páginas para la ruta "agenda"
    const allData = await fetchAllPagesAgenda(lang, title_filter, category_filter, time_start_min, time_start_max, items_per_page);
    allAgendas = allAgendas.concat(allData);

    // Organizar los resultados en la estructura necesaria
    let testCards = [];
    allAgendas.forEach(element => {
        let testElement = {
            id: element.nid ? element.nid : "",
            title: element.title ? element.title : "",
            images: (element.images != null && element.images.length > 0) ? (regex.exec(element.images) ? regex.exec(element.images)[1] : "") : "",
            content: element.content ? element.content : "",
            price: element.price ? element.price : "",
            buy_link: element.buy_link ? element.buy_link : "",
            location_title: element.location_title ? element.location_title : "",
            location_address: element.location_address ? element.location_address : "",
            location_latitude: element.location_latitude ? element.location_latitude : "",
            location_longitude: element.location_longitude ? element.location_longitude : "",
            more_info: element.price ? element.price : "",
            video: element.video ? element.video : "",
            show_in_planner: element.price ? element.price : "",
            planner_target_audicence: element.price ? element.price : "",
            period_start: element.period_start ? formatDate(element.period_start) : "",
            period_end: element.period_end ? formatDate(element.period_end) : "",
            time_start: element.time_start ? formatDate(element.time_start) : "",
            time_end: element.time_end ? formatDate(element.time_end) : "",
            pager: {
                current_page: page,
                total_items: allAgendas.length,
                total_pages: Math.ceil(allAgendas.length / 10),
                items_per_page: 10
            }
        }
        testCards.push(testElement);
    });

    // Paginación manual de los resultados combinados
    const itemsPerPage = 10; 
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = testCards.slice(start, end);

    return paginatedData;
}

export async function getActivityAgenda(lang, nid, config) {
    let testCards = [];
    const regex = /<img.*?src=['"](.*?)['"]/;
    await ServiceEndpoints.currentContent("agenda", lang, nid, config)
      .then((result) => {
        result.data.rows.forEach((element) => {
          let testElement = {
            id: element.nid ? element.nid : "",
            title: element.title ? element.title : "",
            images: (element.images != null && element.images.length > 0) ? (regex.exec(element.images) ? regex.exec(element.images)[1] : "") : "",
            content: element.content ? element.content : "",
            price: element.price ? element.price : "",
            buy_link: element.buy_link ? element.buy_link : "",
            location_title: element.location_title ? element.location_title : "",
            location_address: element.location_address ? element.location_address : "",
            location_latitude: element.location_latitude ? element.location_latitude : "",
            location_longitude: element.location_longitude ? element.location_longitude : "",
            more_info: element.price ? element.price : "",
            video: element.video ? element.video : "",
            show_in_planner: element.price ? element.price : "",
            planner_target_audicence: element.price ? element.price : "",
            period_start: element.period_start ? formatDate(element.period_start) : "",
            period_end: element.period_end ? formatDate(element.period_end) : "",
            time_start: element.time_start ? formatDate(element.time_start) : "",
            time_end: element.time_end ? formatDate(element.time_end) : "",
            pager: result.data.pager,
          };
          testCards.push(testElement);
        });
  
        return testCards;
      })
      .catch((error) => {
        throw error;
      });
    return testCards;
  }

function filterToUnixTimestamp(dateString) {
    if (!dateString) return null;
    return Math.floor(new Date(dateString).getTime() / 1000);
}

function formatDate(isoDateString) {
    const date = new Date(Date.parse(isoDateString));
  
    if (isNaN(date)) {
      // Handle invalid date strings
      return "Invalid Date";
    }
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
}
  
  
