<template>
  <v-app-bar :key="bannerImage" name="app-bar" elevation="0"
    :class="{ 'v-app-bar-class': !scrolled, 'v-app-bar-small': scrolled }" :image="bannerImage">
    <template v-slot:image>
      <v-img v-if="!headerCarousel" gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)"
        :alt="bannerAltText"></v-img>
      <v-img v-if="headerCarousel && itemsCarousel.length > 0" :src="itemsCarousel[0].src" :alt="itemsCarousel[0].alt"
        class="header-image">
      </v-img>
      <v-btn v-if="this.$route.fullPath != '/'" class="zoom-icon" icon color="white" :aria-label="$t('header.see-image')"
        target="_blank" @click="onShowImageZoom(itemsCarousel[0])">
        <v-icon class="zoom-icon-glass">mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <!-- <v-carousel v-if="headerCarousel" hide-delimiters class="header-carousel">
        <v-carousel-item v-for="(item, i) in itemsCarousel" :key="i" :src="item.src" :alt="item.alt" cover>
          <v-btn class="zoom-icon" icon color="white" target="_blank" @click="onShowImageZoom(item)">
            <v-icon class="zoom-icon-glass">mdi-magnify-plus-outline</v-icon>
          </v-btn>
        </v-carousel-item>
      </v-carousel> -->
    </template>

    <v-spacer></v-spacer>

    <v-img @click="!shouldHideLinks ? this.$router.push('/') : this.$router.push(`/?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`)" class="logo" src="@/assets/lloret-logo.webp"
      :alt="$t('logos.header')"></v-img>

    <v-spacer></v-spacer>

    <!-- <v-btn v-if="!shouldHideLinks" class="whatsapp-icon" icon color="white" :href="`https://wa.me/${PHONE_NUMBER}`" target="_blank"
      aria-label="Whatsapp">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn> -->

    <div v-if="!shouldHideLinks" class="whatsapp-icon" @click="sendToWhatsapp">
      <img src="/img/icons/whatsapp.png" alt="Whatsapp">
    </div>

    <div v-if="headerChange" class="text-info">
      <v-btn class="back-icon" icon color="white" :aria-label="$t('header.back-button')" @click="onGoBack">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h1>{{ getSectionName }}</h1>
    </div>

    <div v-if="!headerChange && !headerCarousel" class="text-overlay">
      <h1>
        <span>{{ $t("header.welcome") }}</span><br>
        <strong>LLoret de Mar</strong>
      </h1>
      <h2>{{ $t("header.desc") }}</h2>
    </div>
  </v-app-bar>
</template>

<script>
import { getCurrentInfoData } from "../services/serviceEndpoints";
import { getContentType } from "../utils/utils";
import { mapState } from "vuex";

export default {
  data: () => ({
    PHONE_NUMBER: 34618263898,
    api: "https://cms.lloret.cat",
    drawer: false,
    group: null,
    cardData: null,
    contentId: "",
    descContent: {},
    imageModal: false,
    bannerImage: "https://www.almirall.info/assets/cache/uploads/almirall/entorn/1920x1036/lloret-de-mar-costa-apartaments-almirall-1676546020.jpeg",
    bannerAltText: 'Lloret de Mar App',
    itemsCarousel: [],
    langParam: ''
  }),
  props: {
    scrolled: Boolean,
    headerChange: Boolean,
    cardName: String,
    headerCarousel: Boolean,
  },
  computed: {
    shouldHideLinks() {
      return this.$route.query.hideLinks === 'yes';
    },
    getSectionName() {
      switch (this.$route.fullPath) {
        case "/": return "";
        case "/info/beaches":return this.$t('pages.beaches');
        case "/info/activities":return this.$t('pages.activities');
        case "/info/agenda": return this.$t('pages.agenda');
        case "/info/festivals": return this.$t('pages.festivals');
        case "/info/culture": return this.$t('pages.culture');
        case "/info/what-to-do": return this.$t('pages.what-to-do');
        default: return "";
      }
    },
    getPath() {
      return this.$route.fullPath;
    },
    ...mapState(["language"]),
  },
  methods: {
    sendToWhatsapp(){
      window.location.href = `https://wa.me/${this.PHONE_NUMBER}`
    },
    onGoBack() {
      this.$router.go(-1);
    },
    goToPath(route) {
      this.$router.push(route);
    },
    onShowImageZoom(image) {
      this.imageModal = true;
      this.$emit("isModalImage", this.imageModal, image);
    },
    extractSegmentFromURL(inputURL) {
      const regex = /\/info\/([^/]+)/;
      const match = inputURL.match(regex);

      if (match && match[1]) {
        return match[1];
      }
      return "";
    },
    getIdFromRoute() {
      const fullPath = this.$route.fullPath;
      const regex = /id=(\d+)/;
      const match = fullPath.match(regex);

      return match ? match[1] : null;
    },
    async getCardsData(language) {
  
      this.contentId = this.getIdFromRoute();
      this.cardData = await getCurrentInfoData(getContentType("/info/" + this.extractSegmentFromURL(this.$route.path)), language, this.contentId);

      for (let card of this.cardData) {
        this.descContent = card;
      }

      if (this.descContent.images == "") {

        let auxImage = {
          src: "https://www.almirall.info/assets/cache/uploads/almirall/entorn/1920x1036/lloret-de-mar-costa-apartaments-almirall-1676546020.jpeg",
          alt: 'Lloret de Mar App'
        };
        this.itemsCarousel = [];
        this.itemsCarousel.push(auxImage);
        this.bannerImage = auxImage.src;
        this.bannerAltText = auxImage.alt;
      }else {
        let auxImage = {
          src: this.api + this.descContent.images,
          alt: this.descContent.title
        };
        this.itemsCarousel = [];
        this.itemsCarousel.push(auxImage);
        this.bannerImage = auxImage.src;
        this.bannerAltText = auxImage.alt;
      }
    },
  },
  created() {
    this.sectionName = this.cardName;
  },
  mounted(){
    const params = new URLSearchParams(window.location.search);
    this.langParam = params.get('language');
  },
  updated() {
    this.sectionName = this.cardName;
  },
  watch: {
    group() {
      this.drawer = false;
    },
    async getPath() {
      if (this.$route.fullPath === "/" || this.$route.fullPath === "/?hideLinks=yes") {
        this.bannerImage = "https://www.almirall.info/assets/cache/uploads/almirall/entorn/1920x1036/lloret-de-mar-costa-apartaments-almirall-1676546020.jpeg";
        this.sectionName = "";
        this.bannerAltText = 'Lloret de Mar App'
      } else if (this.$route.fullPath === "/info/beaches" || this.$route.fullPath === "/info/beaches?hideLinks=yes") {
        this.bannerImage = "https://s.libertaddigital.com/2022/06/23/platjasaboadella.jpg";
        this.sectionName = "Playas";
        this.bannerAltText = this.$t('pages.beaches')
      } else if (this.$route.fullPath === "/info/activities" || this.$route.fullPath === "/info/activities?hideLinks=yes") {
        this.bannerImage = "https://www.visitacostabrava.com/media/items/372db-parasailing-lloret-4.jpg";
        this.sectionName = "Actividades";
        this.bannerAltText = this.$t('pages.activities')
      } else if (this.$route.fullPath === "/info/agenda" || this.$route.fullPath === "/info/agenda?hideLinks=yes") {
        this.bannerImage = "https://magazinestartups.com/wp-content/uploads/2023/07/LLORET_DRONS_1.jpeg";
        this.sectionName = "Agenda";
        this.bannerAltText = this.$t('pages.agenda')
      } else if (this.$route.params.id != null) {
        await this.getCardsData(this.language);
      }
    },
  },
};
</script>

<style>
.v-navigation-drawer__content{
  padding-top: 15px;
}
.lang-container {
  position: absolute;
  top:10px;
  left: 70px;
  z-index: 1;
  width: 70px;
}
.navegation-drawer {
  height: 100vh !important;
}
.whatsapp-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 11;
}

.whatsapp-icon img{
  height: 48px;
}

.menu-icon {
  margin: 20px;
  background-color: #fbbf00;
}

.back-icon {
  border: 2px solid #6c757d;
  transform: scale(0.7);
  margin-right: 20px;
}

.back-icon i{
  color: #6c757d;
}

.logo {
  width: 55px;
  height: 40px;
  cursor: pointer;
  margin-left: 90px;
}

.v-app-bar-class {
  height: 350px;
}

.text-overlay {
  position: absolute;
  top: 250%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  text-align: center;
  width: 100%;
  padding: 20px;
  color: white;
}

.text-overlay span{
  font-size: 70%;
  font-weight: normal;
}

.zoom-icon {
  position: absolute;
  top: 280px;
  right: 40px;
  transform: scale(1.2);
}

.zoom-icon-glass {
  transform: scale(1.7);
}

.text-info {
  position: absolute;
  top: 170%; /* Center vertically */
  left: 10%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  text-align: center;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-info h1 {
  font-size: 38px;
  font-weight: 800;
  color: white;
}

.text-overlay h2 {
  font-size: 21px;
  margin-bottom: 10px;
  font-weight: 300;
}

.text-overlay h1 {
  font-size: 42px;
  margin-bottom: 10px;
  font-weight: 800;
}

.text-overlay h3 {
  font-size: 20px;
  font-weight: 200;
}

.v-toolbar {
  position: static !important;
}

.v-app-bar-class {
  height: 350px;
}

.v-app-bar-small {
  height: 160px; /* Adjust the desired smaller height */
  transition: height 0.3s; /* Add a smooth transition effect */
}

@media (max-width: 1660px) {
  .text-info {
    top: 170%;
    left: 10%;
  }

  .text-info h1 {
    font-size: 32px;
  }
}

@media (max-width: 1300px) {
  .text-info {
    left: 20%;
  }
}

@media (max-width: 760px) {
  .text-info {
    left: 30%;
  }
}

@media (max-width: 450px) {
  .text-info {
    left: 40%;
  }
  .logo {
    margin-left: 120px;
  }
  .whatsapp-icon{
    right: 7px;
    top: 12px;
  }
  .whatsapp-icon img{
    height: 45px;
  }
}
</style>
