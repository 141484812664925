<template>
  <div class="carousel-container">
    <Carousel v-bind="settings" :autoplay="10000" :breakpoints="breakpoints" class="main-carousel">
      <Slide v-for="slide in imageArray" :key="slide">
        <CarouselCardComponent :slide="slide" class="carrusel_card"></CarouselCardComponent>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import CarouselCardComponent from "./CarouselCardComponent.vue";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    CarouselCardComponent,
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        401: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        551: {
          itemsToShow: 2.45,
          snapAlign: "center",
        },
        701: {
          itemsToShow: 3.1,
          snapAlign: "start",
        },
        1025: {
          itemsToShow: 4,
          snapAlign: "start",
        },
        1401: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      imageArray: [],
    };
  },
  computed: {
    shouldHideLinks() {
      return this.$route.query.hideLinks === 'yes';
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get('language');
    this.imageArray = (!this.shouldHideLinks) ? [
      {
        name: this.$t("pages.beaches"),
        path: "https://s.libertaddigital.com/2022/06/23/platjasaboadella.jpg",
        link: "beaches",
        desc: "",
      },
      {
        name: this.$t("pages.agenda"),
        path: "https://www.hotelacapulcolloret.com/wp-content/uploads/2013/04/cala-banys.jpg",
        link: "agenda",
        desc: "",
      },
      {
        name: this.$t("pages.activities"),
        path: "https://www.visitacostabrava.com/media/items/372db-parasailing-lloret-4.jpg",
        link: "activities",
        desc: "",
      },
      {
        name: this.$t("pages.culture"),
        path: "https://media.revistaad.es/photos/60c220fc2d9a1d168442265f/master/w_1600%2Cc_limit/286032.jpg",
        link: "culture",
        desc: ""
      }
    ] : [
      {
        name: this.$t("pages.beaches"),
        path: "https://s.libertaddigital.com/2022/06/23/platjasaboadella.jpg",
        link: `beaches?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
        desc: "",
      },
      {
        name: this.$t("pages.agenda"),
        path: "https://www.hotelacapulcolloret.com/wp-content/uploads/2013/04/cala-banys.jpg",
        link: `agenda?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
        desc: "",
      },
      {
        name: this.$t("pages.activities"),
        path: "https://www.visitacostabrava.com/media/items/372db-parasailing-lloret-4.jpg",
        link: `activities?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
        desc: "",
      },
      {
        name: this.$t("pages.culture"),
        path: "https://media.revistaad.es/photos/60c220fc2d9a1d168442265f/master/w_1600%2Cc_limit/286032.jpg",
        link: `culture?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
        desc: ""
      }
    ]
  },
});
</script>


<style scoped>
.carousel-container {
  width: 100%;
  /* Take full width of the container */
  margin: 0 auto;
  /* Center the container horizontally */
  padding: 20px 15px;
  /* Add horizontal spacing */
  /* Flexbox properties for vertical centering */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel__slide {
  padding: 10px;
  padding-top: 20px;
}

.main-carousel {
  width: 75%;
  /* margin-left: 20%; */
}

.carrusel_card {
  margin-right: 8px;

}

@media (max-width: 1400px) {
  .main-carousel {

    width: 100%;
    /* margin-left: 20%; */
  }
}

@media (max-width: 1024px) {
  .main-carousel {

    width: 100%;
    /* margin-left: 20%; */
  }
  .carousel__slide {
  padding: 5px;
  padding-top: 20px;
  width: 32% !important;
}

}
@media (max-width: 700px) {
  .carousel__slide {
  width: 40% !important;
}

}
@media (max-width: 550px) {
  .carousel__slide {
  width: 50% !important;
}

}
@media (max-width: 450px) {
  .carousel__slide, .carousel-container .cardImage {
    width: 100% !important;
  }
}
</style>
