import { createStore } from 'vuex';

export default createStore({
  state: {
    language: localStorage.getItem('lang') || 'ca', // Initialize with the language from localStorage or a default value
  },
  mutations: {
    setLanguage(state, newLanguage) {
      state.language = newLanguage;
      localStorage.setItem('lang', newLanguage); // Update localStorage with the new language
    },
  },
  actions: {
    changeLanguage({ commit }, newLanguage) {
      commit('setLanguage', newLanguage);
    },
  },
});
