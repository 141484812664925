const he = require('he');

export function getContentType(route) {
    switch (route) {
        case "/info/beaches":
            return "platja"
        case "/info/activities":
            return "activity"
        case "/info/agenda":
            return "agenda"
        case "/info/culture":
            return "culture"
        default:
            return ""
    }
}


export const decodePayload = (token) => {
    try{
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let payload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(payload)
    }catch(error) {
        return false
    }
}

export function errorServiceManagement(error) {
    if (error.response) {
        if (error.response.status === 401) {
            console.log('Error 401');
        }
        if (error.response.status === 404) {
            console.log('Error 404');
        }
        if (error.response.status === 500) {
            console.log('Error 500');
        }
    }
}

export function transformTitleToLink(title){
    let link = ""
    let splitTitle = title.split(" ")
    splitTitle.forEach(element => {
        link = link + element.toLowerCase() + "-"
    });
    link = link.substring(0, link.length - 1);
    return link
} 

export function setCharacterLimit(info, limit){
    if (info.length > limit) {
    return info.substring(0, limit) + "...";
    } else {
    return info;
  }
}

export function getTextFromATag(tag) {
    let text = tag.indexOf(">");
    if (text !== -1) {
        return decodeHTML(tag.substring(text + 1).replace("</a>", ""))
    } else {
        return decodeHTML(tag)
    }
}

function decodeHTML(text) {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return he.decode(textarea.value);
}

export function updateMetaTag(name, content) {
    // Método para actualizar una etiqueta meta en el DOM
    const existingTag = document.querySelector(`meta[name="${name}"]`);
    if (existingTag) {
      // Si la etiqueta ya existe, actualízala
      existingTag.setAttribute('content', content);
    } else {
      // Si la etiqueta no existe, créala y agrégala al <head>
      const newTag = document.createElement('meta');
      newTag.setAttribute('name', name);
      newTag.setAttribute('content', content);
      document.head.appendChild(newTag);
    }
  }

export function ariaLabelTransform(text){
    const indiceGuion = text.indexOf('-');
    if (indiceGuion !== -1) {
      return text.substring(indiceGuion + 1);
    } else {
      return text;
    }
  }
