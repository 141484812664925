<template>
    <div>
        <v-pagination v-model="auxPage" :length="totalPages" rounded="circle" total-visible="5" 
            @update:modelValue="onUpdated">
        </v-pagination>
    </div>
</template>
<script>
export default {
    props: {
        page: {
            type: Number,
            default: 1
        },
        totalPages: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            auxPage: 1,
        }
    },
    created() {
        this.auxPage = this.page
    },
    updated() {
        this.auxPage = this.page
    },
    methods: {
        onUpdated(value) {
            this.$emit("change-value", value)
        },
    }
}
</script>
<style scoped>
.v-pagination{
    margin-bottom: 25px;
}
</style>